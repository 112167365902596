<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Validasi</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-clipboard mg-r-5"></i>Validasi Pengajuan</a>
            </div>
        </div> 

        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari No Bukti/No Dokumen/Tgl Dokumen/Tgl Buku...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>No Dokumen</th>
                                            <th>No Bukti</th>
                                            <th>Tgl Dokumen</th>
                                            <th>Item Pengajuan</th>
                                            <th>Pengusul</th>
                                            <th class="text-center" style="width:10%">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataTransaksi.length == 0">
                                        <tr>
                                            <td colspan="7">
                                                <div class="alert alert-danger text-center" role="alert">
                                                    Belum Ada Data
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(item, index) in dataTransaksi" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.no_dokumen }}</td>
                                            <td>{{ item.no_bukti }}
                                            <a v-if="item.lampiran" :href="$apiconfig + 'uploads/' + item.lampiran" target="_blank" style="cursor:pointer" class="text-primary"><i class="fa fa-external-link ml-2"></i></a></td>
                                            <td>{{ item.tgl_dokumen }}</td>
                                            <td >{{ item.item }}</td>
                                            <td>{{ item.nickname }} </td>
                                            <td class="text-center">
                                                <button v-if="item.proses == 'send' && index == 0" @click.prevent="ajukanData(item.id)" class="btn btn-outline-danger btn-sm mr-2"><i class="fe fe-send mr-2"></i>Ajukan</button>
                                                <button v-if="item.proses == 'send' && index > 0" disabled style="cursor:none" class="btn btn-outline-secondary btn-sm mr-2"><i class="fe fe-send mr-2"></i>Ajukan</button>
                                                <button v-if="item.proses == 'wait'" @click.prevent="validasiData(item.id)" class="btn btn-outline-success btn-sm mr-2"><i class="fa fa-check-square-o mr-2"></i>Validasi</button>
                                                <router-link :to="{ name:'transaksi_masuk.form_pengajuan',params: { id: item.id }}">
                                                <button v-if="item.proses == 'send'" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                </router-link>
                                                <button v-if="item.proses == 'send'" @click.prevent="postDelete(item.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 7,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                currencyMask,
                dataTransaksi: [],
                fetching: true,
                status: 'pengajuan',
                form: {
                    id: '',
                    code: '',
                    satuan: '',
                    name: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData();
        },
        components: {
            vPagination
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'transaksi/pengajuan/validasi', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        status: this.status,
                    }
                })
                    .then((response) => {
                        this.dataTransaksi = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            validasiData(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$http({
                    url: this.baseUrl + 'transaksi/pengajuan/proses-validasi',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if(response.data.status){
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text:  response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.getData();
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        })
                    }
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
            rejectData(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$http({
                    url: this.baseUrl + 'transaksi/pengajuan/proses-reject',
                    method: 'post',
                    data: fd
                }).then((response) => {
                    if(response.data.status){
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text:  response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.getData();
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        })
                    }
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>